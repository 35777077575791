/**
 * Блок подключения модулей
 */
/**
 * React
 */
import React from 'react';

/**
 * MUI
 */
import {
  Box,
  Container,
  Grid, Paper, Grow, Button, Typography, Stack
} from "@mui/material";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

export const IndexPage = () => {
  return (
    <Grow
      in
    >
      <Box>
        <Paper sx={{p: 4}}>
          <Container>
            <Grid
              container
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item>
                <Typography variant={'h5'}>
                  Сайт находится на реконструкции
                </Typography>
                <Typography variant={'h5'}>
                  Повторите попытку позже
                </Typography>
                <Typography variant={'body2'} gutterBottom>
                  Архив фото можно посмотреть в канале Telegram
                </Typography>
                <Stack direction={'column'} spacing={1}>
                  <Button
                    size={'large'}
                    endIcon={<OpenInNewOutlinedIcon/>}
                    href={'https://t.me/oleg_guriev/'}
                    target={'_blank'}
                  >
                    Архив фото
                  </Button>
                  <Button
                    size={'large'}
                    endIcon={<OpenInNewOutlinedIcon/>}
                    href={'https://xn--80aecimacmz9ato.xn--p1ai/'}
                    target={'_blank'}
                  >
                    Автор сайта
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Box>
    </Grow>
  )
}