import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Avatar
} from '@mui/material';
import favicon from '../../img/angelBW.svg';

export default function NavBar(props) {
  return (
    <Box>
      <AppBar position="static">
        <Container>
          <Toolbar>
            <Avatar
              variant="square"
              src={favicon}
              alt="Логотип сайта"
              sx={{mr: 2}}
            />
            <Typography
              variant="h6"
            >
              {props.siteName}
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}